'use client';
import { useState, useEffect, ReactNode, useCallback } from 'react';
import {
   Box,
   Text,
   Link,
   Button,
   ButtonGroup,
   Stack,
   UnorderedList,
   ListItem,
   ButtonProps,
   useDisclosure,
} from '@chakra-ui/react';
import { _js } from '@ifixit/localize';
import { GTag } from '@ifixit/analytics';
import { connect } from 'react-redux';

const NOT_SEEN = -1;
const REJECTED = 0;
const ACCEPTED = 1;

type ConsentType =
   | 'analytics'
   | 'ab_testing_and_personalization'
   | 'conversion_tracking'
   | 'marketing_automation'
   | 'remarketing'
   | 'user_feedback'
   | 'custom_consent';
type Consents = {
   [type in ConsentType]: {
      status: number;
   };
};

type ppmsType = {
   cm: {
      api: {
         (
            command: 'getNewComplianceTypes',
            onFulfilled: (type: ConsentType[]) => void,
            onRejected?: (error: unknown) => void
         ): void;
         (
            command: 'setInitialComplianceSettings',
            settings: { consents: ConsentType[] },
            onFulfilled: () => void,
            onRejected?: (error: unknown) => void
         ): void;
         (
            command: 'getComplianceSettings',
            onFulfilled: (complianceSettings: { consents: Consents }) => void,
            onRejected?: (error: unknown) => void
         ): void;
         (
            command: 'setComplianceSettings',
            settings: { consents: Consents },
            onFulfilled: () => void,
            onRejected?: (error: unknown) => void
         ): void;
         (command: 'trackAgreeToAllClick'): void;
         (command: 'trackRejectAllClick'): void;
      };
   };
};

declare global {
   interface Window {
      ppms?: ppmsType;
      gtag?: GTag;
   }
}

type ButtonText = {
   acceptAll: string;
   rejectAll: string;
   details: string;
};

const fallBackText = {
   'buttonText': {
      acceptAll: 'Accept All',
      rejectAll: 'Reject All',
      details: 'Details',
   },
   'intro': (
      <>
         iFixit uses cookies to enhance site functionality and analyze traffic. By using our site,
         you agree to our cookie use and{' '}
         <Link href="/Info/Privacy" size="inherit" textDecoration={'underline'}>
            Privacy Policy.
         </Link>
      </>
   ),
   'details': (
      <>
         <Text m={0} fontWeight="600">
            If you accept all cookies:
         </Text>
         <UnorderedList m={0} mt={1} pl={6}>
            <ListItem>Our site may be improved based on your interests</ListItem>
            <ListItem>Your preferences may be stored</ListItem>
            <ListItem>You may see advertisements that are suitable for you</ListItem>
         </UnorderedList>
      </>
   ),
};

const stateToProps = (state: {
   consentBannerState: { bannerIsOpen: boolean; detailsIsOpen: boolean };
}) => ({
   isOpenOverride: state.consentBannerState.bannerIsOpen,
   showDetails: state.consentBannerState.detailsIsOpen,
});
const dispatchToProps = (
   dispatch: ({ type, value }: { type: string; value: unknown }) => void
) => ({
   setShowBanner: (value: boolean) => dispatch({ type: 'TOGGLE_BANNER', value }),
   setShowDetails: (value: boolean) => dispatch({ type: 'TOGGLE_DETAILS', value }),
});

export const ConsentComponent = connect(
   stateToProps,
   dispatchToProps
)(
   ({
      isOpenOverride,
      showDetails,
      setShowBanner,
      setShowDetails,
      buttonText = fallBackText.buttonText,
      intro = fallBackText.intro,
      details = fallBackText.details,
   }: {
      isOpenOverride: boolean;
      showDetails: boolean;
      setShowBanner: (val: boolean) => void;
      setShowDetails: (val: boolean) => void;
      buttonText?: ButtonText;
      intro?: ReactNode;
      details?: ReactNode;
   }) => {
      const { isOpen: showBanner, onOpen, onClose } = useDisclosure({ isOpen: isOpenOverride });
      const [consents, setConsents] = useState<Consents | null>(null);

      const closeBanner = () => {
         setShowDetails(false);
         setShowBanner(false);
         onClose();
      };

      const openBanner = useCallback(() => {
         onOpen();
         setShowBanner(true);
      }, [onOpen, setShowBanner]);

      useEffect(() => {
         const ppms = window?.ppms;
         if (!ppms) {
            return;
         }

         ppms.cm.api('getNewComplianceTypes', (newConsentTypes: ConsentType[]) => {
            if (newConsentTypes.length === 0) {
               return;
            }
            ppms.cm.api('setInitialComplianceSettings', { consents: newConsentTypes }, () => {
               setConsents(getDefaultConsents(newConsentTypes));
               openBanner();
            });
         });
         ppms.cm.api('getComplianceSettings', (complianceSettings: { consents: Consents }) => {
            const savedConsents = complianceSettings.consents;
            setConsents(savedConsents);
            for (const consentType in savedConsents) {
               const consent = savedConsents[consentType as ConsentType];
               if (consent.status === NOT_SEEN) {
                  openBanner();
                  return;
               }
            }
         });
      }, [openBanner]);

      if (typeof window === 'undefined') {
         return null;
      }
      const { ppms, gtag } = window;

      if (ppms === undefined || ppms.cm === undefined || ppms.cm.api === undefined) {
         return null;
      }

      if (!gtag) {
         return null;
      }

      return showBanner && consents ? (
         <ConsentBanner
            consents={consents}
            closeConsentForm={closeBanner}
            ppms={ppms}
            gtag={gtag}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
            buttonText={buttonText}
            intro={intro}
            details={details}
         />
      ) : null;
   }
);

function ConsentBanner({
   consents,
   closeConsentForm,
   ppms,
   gtag,
   showDetails,
   setShowDetails,
   buttonText,
   intro,
   details,
}: {
   consents: Consents;
   closeConsentForm: () => void;
   ppms: ppmsType;
   gtag: (...args: unknown[]) => void;
   showDetails: boolean;
   setShowDetails: (val: boolean) => void;
   buttonText: ButtonText;
   intro: ReactNode;
   details: ReactNode;
}) {
   useEffect(() => {
      setShowDetails(showDetails);
   }, [setShowDetails, showDetails]);

   function setStatusForAll(status: number) {
      for (const consentType in consents) {
         consents[consentType as ConsentType].status = status;
      }
      ppms.cm.api('setComplianceSettings', { consents }, closeConsentForm);
      const GTMConsentValue = status === 1 ? 'granted' : 'denied';
      gtag('consent', 'update', {
         'ad_storage': GTMConsentValue,
         'ad_user_data': GTMConsentValue,
         'ad_personalization': GTMConsentValue,
         'analytics_storage': GTMConsentValue,
      });
   }

   function agreeToAll() {
      setStatusForAll(ACCEPTED);
      ppms.cm.api('trackAgreeToAllClick');
   }

   function rejectAll() {
      setStatusForAll(REJECTED);
      ppms.cm.api('trackRejectAllClick');
   }

   return (
      <Box
         data-test-id="consent-banner"
         px={3}
         pb={{ base: 6, sm: 3 }}
         position="fixed"
         left={'50%'}
         bottom={0}
         transform="translate(-50%, 0)"
         zIndex="popover"
         width="100%"
         alignContent={'center'}
         fontSize="md"
      >
         <Box
            maxW="1024px"
            mx="auto"
            p={6}
            background="white"
            border="1px"
            borderColor="gray.200"
            borderRadius="lg"
            boxShadow="lg"
         >
            <Stack
               mx="auto"
               direction={{ base: 'column', sm: 'row' }}
               alignItems={{ base: 'start', sm: 'center' }}
               spacing={{ base: 2, sm: 3 }}
            >
               <Text m={0} fontSize="inherit">
                  {intro}
               </Text>
               {!showDetails && (
                  <ButtonGroup spacing={{ base: 2, sm: 3 }} size={{ base: 'xs', sm: 'sm' }}>
                     <SecondaryButton
                        onClick={() => setShowDetails(true)}
                        data-test-id="details-button"
                     >
                        {buttonText.details}
                     </SecondaryButton>
                     <PrimaryButton onClick={agreeToAll} data-test-id="accept-all-button">
                        {buttonText.acceptAll}
                     </PrimaryButton>
                  </ButtonGroup>
               )}
            </Stack>
            {showDetails && (
               <Details
                  agreeToAll={agreeToAll}
                  rejectAll={rejectAll}
                  buttonText={buttonText}
                  details={details}
               />
            )}
         </Box>
      </Box>
   );
}

function Details({
   agreeToAll,
   rejectAll,
   buttonText,
   details,
}: { agreeToAll: () => void; rejectAll: () => void; buttonText: ButtonText; details: ReactNode }) {
   return (
      <Box data-test-id="details" mt={{ base: 2, sm: 3 }}>
         <ButtonGroup spacing={{ base: 2, sm: 3 }} size={{ base: 'xs', sm: 'sm' }}>
            <SecondaryButton onClick={rejectAll} data-test-id="reject-all-button">
               {buttonText.rejectAll}
            </SecondaryButton>
            <PrimaryButton onClick={agreeToAll} data-test-id="accept-all-button">
               {buttonText.acceptAll}
            </PrimaryButton>
         </ButtonGroup>
         <Box mt={{ base: 3, sm: 6 }}>{details}</Box>
      </Box>
   );
}

const SecondaryButton = ({ children, ...buttonProps }: { children: ReactNode } & ButtonProps) => (
   <Button color="black" fontSize="inherit" fontWeight="600" background="gray.200" {...buttonProps}>
      {children}
   </Button>
);

const PrimaryButton = ({ children, ...buttonProps }: { children: ReactNode } & ButtonProps) => (
   <Button variant="cta" fontSize="inherit" {...buttonProps}>
      {children}
   </Button>
);

function getDefaultConsents(consentTypes: string[]): Consents {
   const consents = {} as Consents;
   for (const consentType in consentTypes) {
      consents[consentType as ConsentType] = { status: NOT_SEEN };
   }
   return consents;
}
