import { Reducer, Store } from 'redux';
import { useSelector } from 'react-redux';
import { RootState } from 'Shared/ifixit_store';

type Selector<T> = (state: RootState) => T;
type Setter<T> = (store: Store) => (value: T) => void;
type Hook<T> = () => [T, Setter<T>];

interface SharedState<T> {
   name: string;
   reducer: Reducer<T>;
   selector: Selector<T>;
   setter: Setter<T>;
   hook: Hook<T>;
}

/**
 * Generates a reducer, selector, setter (combined action
 * creation/dispatch), and a convenient hook for a set-only piece of
 * state.
 */
export function createSharedState<T>(
   name: string,
   // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'T'.
   initialValue: T = null,
   // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'Selector<T>... Remove this comment to see the full error message
   selector: Selector<T> = null
): SharedState<T> {
   const actionName = 'set' + name;
   const reducer = (state = initialValue, action: any) =>
      action.type === actionName ? action.value : state;

   const defaultSelector = (state: RootState) => state[name];
   const localSelector: Selector<T> = selector === null ? defaultSelector : selector;
   const setter: Setter<T> = (store: Store) => (value: T) =>
      store.dispatch({ type: actionName, value: value });
   // eslint-disable-next-line react-hooks/rules-of-hooks -- FIXME: React Hook "useSelector" is called in "hook: Hook<T>" that is neither a React function component nor a custom Hook ...
   const hook: Hook<T> = () => [useSelector(localSelector), setter];

   return {
      name,
      reducer,
      selector: localSelector,
      setter,
      hook,
   };
}
