import { AnyAction } from 'redux';

type BannerStateType = {
   bannerIsOpen: boolean;
   detailsIsOpen: boolean;
};

const initialState: BannerStateType = {
   bannerIsOpen: false,
   detailsIsOpen: false,
};

export const consentBannerReducer = (state = initialState, action: AnyAction) => {
   switch (action.type) {
      case 'TOGGLE_BANNER': {
         return { ...state, bannerIsOpen: action.value };
      }
      case 'TOGGLE_DETAILS': {
         return { ...state, detailsIsOpen: action.value };
      }
      default: {
         return state;
      }
   }
};
